<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">RADONKRIT COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li
          :class="{ 'active-link': isActive('/') || isActive('/vn/') }"
          class="Li1"
        >
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Trang chủ</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/vn/about' }" class="Li2">
          <router-link to="/vn/about" class="nav-link2">
            <span class="routeText2">Về chúng tôi</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/vn/question' }"
          class="Li3"
        >
          <router-link to="/vn/question" class="nav-link3">
            <span class="routeText3">Trung tâm trợ giúp</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/vn/protocol' }"
          class="Li4"
        >
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Cam kết bảo mật</span>
          </router-link> -->
          <a
            href="https://now.happycredito.com/B9tK7aQ4p/j5oYsj8U7ysxy.html?V3mT1yU6b=happycredit"
            class="nav-link3"
            target="_blank"
          >
            <span class="routeText4">Chính sách bảo mật</span>
          </a>
        </li>
        <li
          class="dropdown Li2"

        >
          <router-link to="/vn/" class="nav-link7">
            <span class="dropdown-toggle routeText7"
              ><img
                class="icon1"
                src="../vn/components/img/vnIcon.png"
                alt="" />Việt Nam <img id="image" :src="image1" alt="Image"
            /></span>
          </router-link>
          <ul class="dropdown-menu" ref="dropdownMenu">
            <li style="color: black">
              <router-link to="/th/" class="nav-link2">
                <span class="dropdown-toggle routeText6"><img
                  class="icon1"
                  src="../vn/components/img/thIcon.png"
                  alt="" />ประเทศไทย</span>
              </router-link>
              <!-- <a
              href="http://qmoney.gomatrixcompany.com/"
              class="nav-link2"
              target="_blank"
            >
              <span class="dropdown-toggle routeText6">English</span>
            </a> -->
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 110px"></div> -->
    <!-- <div class="modBox" ref="mod">
      <a-modal v-model="open" :getContainer="() => $refs.mod" :closable="false" :footer="null" :mask="false"
        :maskClosable="false">
        <div class="clickBox1" @click="close()"></div>
        <div class="clickBox2" @click="gotoCountry('vn')"></div>
        <div class="clickBox3" @click="gotoCountry('th')"></div>
      </a-modal>
    </div>
    <div class="modBox" ref="mod">
      <a-modal v-model="open" :getContainer="() => $refs.mod" :closable="false" :footer="null" :mask="false"
        :maskClosable="false">
        <div class="mod-title">Vui lòng chọn ngôn ngữ của quốc gia bạn โปรดเลือกภาษาของประเทศของคุณ</div>
        <div class="mod-row">
          <img :src="vnIcon" alt="" class="countryIcon" />
          <div class="selectBox" @click="gotoCountry('vn')">Tiếng Việt</div>
        </div>
        <div class="mod-row">
          <img :src="thIcon" alt="" class="countryIcon" />
          <div class="selectBox" @click="gotoCountry('th')">แบบไทย</div>
        </div>
      </a-modal>
    </div> -->
  </nav>
</template>

<script>
import image1 from "@/page/radonkritcompany/vn/components/img/0_0.png";
import image2 from "@/page/radonkritcompany/vn/components/img/0.png";
// import vnIcon from "@/page/radonkritcompany/vn/components/img/vnIcon.png";
// import thIcon from "@/page/radonkritcompany/vn/components/img/thIcon.png";
// import modalBg from "@/page/radonkritcompany/vn/components/img/modalBg.png";
export default {
  data() {
    return {
      image1: image1,
      image2: image2,
      url1: image1,
      url2: image2,
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
      // vnIcon: vnIcon,
      // thIcon: thIcon,
      open: false,
    };
  },
  mounted() {
    if (window.performance.navigation.type == 1) {
      // console.log("页面被刷新！")
    } else {
      // console.log("首次被加载！")
      if (["/", "/vn/"].includes(this.$route.path)) {
        this.open = true;
      }
    }
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    close() {
      this.open = false;
    },
    gotoCountry(country) {
      if (country === "vn") {
        this.close();
      }
      if (country === "th") {
        this.$router.push("/th/");
      }
    },
    changeImage(isHovered) {
      if (isHovered) {
        this.image1 = this.url2; // 鼠标悬浮时显示的图片
      } else {
        this.image1 = this.url1; // 鼠标离开时显示的图片
      }
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky;
  /* 将导航栏固定在页面上 */
  top: 0;
  /* 位于页面顶部 */
  z-index: 1000;
  /* 确保导航栏在其他元素上方 */
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #00807D;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 460px;
  height: 24px;
  font-size: 16px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 600;
  color: #ffffff;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 80px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: Roboto Flex-Medium;
  /* font-weight: 600; */
  color: #ffffff;
  line-height: 29px;
}

.routeText1 {
  width: 85px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText2 {
  width: 148px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText3 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText4 {
  width: 200px;
  padding-left: 16px;
  padding-right: 8px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 49px;
  font-weight: 500;
  line-height: 39px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  padding-top: 5px;
  padding-bottom: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
  /* border: 1px solid  red; */
  text-align: center;
}

.Li1 {
  width: 140px;
}

.Li2 {
  width: 160px;
}

.Li3 {
  width: 190px;
}

.Li4 {
  width: 240px;
}

.lastLi {
  width: 190px;
  margin-right: 28px;
}

/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #ffffff;
}

.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  /* 初始时下划线可见 */
  border-top: 4px solid #ffffff;
  border-radius: 20px;
  /* 调整下划线的长度 */
  width: 50%;
  transition: width 0s ease-in-out;
  /* 添加 transition 效果 */
  margin-top: -60px;
  /* 改进的白色阴影效果 */
  box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.6),
    0px 15px 30px rgba(255, 255, 255, 0.3);
}

.active-link .routeText1::after {
  width: 60%;
  margin-left: 36px;
}

.active-link .routeText2::after {
  width: 60%;
  margin-left: 50px;
}

.active-link .routeText3::after {
  margin-left: 30px;
  width: 70%;
}

.active-link .routeText4::after {
  width: 67%;
  margin-left: 35px;
}

.title {
  /* border: 1px solid red; */
  width: 500px;
  /* padding-left: 80px; */
}

.secondBox {
  padding: 0 50px 0 30px;
}

.modBox >>> .ant-modal-content {
  width: 500px;
  background-image: url("@/page/radonkritcompany/vn/components/img/modalBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 310px;
  background-color: #f5fff6;
  margin-top: 70px;
}

/* .modBox>>>.ant-modal-body {
  background-color: #3b4453;
  width: 500px;
  height: 310px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-size: 20px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.modBox >>> .ant-modal-body {
  /* background-image: url("@/page/radonkritcompany/vn/components/img/modalBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 310px; */
}

.countryIcon {
  width: 66px;
  height: 46px;
}

.mod-title {
  width: 400px;
  text-align: center;
  margin: 10px 0 26px;
}

.mod-row {
  display: flex;
  width: 420px;
  margin-bottom: 30px;
  align-items: center;
}

.selectBox {
  width: 230px;
  height: 54px;
  border: 1px solid #ffffff;
  text-align: center;
  line-height: 54px;
  border-radius: 3px;
  margin-left: 20px;
}

.modBox {
  position: relative;
}

.clickBox1 {
  position: absolute;
  background: transparent;
  width: 42px;
  height: 43px;
  top: 0%;
  right: 0%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox2 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 43px;
  top: 51%;
  left: 42%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox3 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 43px;
  top: 73%;
  left: 42%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.dropdown {
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 40px;
  left: 0px;
  font-family: "Thonburi";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding: 5px 0;
  min-width: 160px;
  border-radius: 25px;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(30, 29, 29, 0.4)
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu li {
  height: 40px;
  line-height: 40px;
}

.dropdown-menu li a {
  font-size: 14px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  /* font-weight: 550; */
  /* margin-top: 10px; */
}
.icon1 {
  width: 20%;
}
.routeText7, .routeText6{
 
  display: flex;
  justify-content: left;
  padding-left: 10px;
  gap: 10px;
  align-items: center;
}
.routeText7{
   padding-top: 8px;
}
</style>
